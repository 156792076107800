import React, { useState, useEffect } from 'react';
import styles from './summary-price.module.scss';

const SummaryPrice = ({ reserve, isServiceActive, discountPercentage = 10, tripType }) => {
  const [regularRate, setRegularRate] = useState(0);
  const [promotionRate, setPromotionRate] = useState(0);
  const [extraService, setExtraService] = useState(null);

  useEffect(() => {
    const calculateRates = () => {
      const calculatedRegularRate = Number(((reserve.COSTO * 100) / 90).toFixed(1));
      const calculatedPromotionRate = Number((calculatedRegularRate - calculatedRegularRate * (discountPercentage / 100)).toFixed(1));

      setRegularRate(calculatedRegularRate);
      setPromotionRate(calculatedPromotionRate);
    };

    calculateRates();
  }, [reserve.COSTO, discountPercentage]);

  useEffect(() => {
    if (reserve.SERVICIO_EXTRA) {
      try {
        const parsedService = JSON.parse(reserve.SERVICIO_EXTRA);
        setExtraService(parsedService);
      } catch (error) {
        console.error("Invalid JSON for SERVICIO_EXTRA:", error);
        setExtraService(null);
      }
    } else {
      setExtraService(null);
    }
  }, [reserve.SERVICIO_EXTRA]);

  return (
    <div className={styles.reservationSummary}>
      <div className={styles.summaryServiceTitle}>
        <h4 className={styles.itemTitle}>Service: <span>{tripType}</span></h4>
      </div>
      {discountPercentage > 0 && (
        <div className={styles.summaryItem}>
          <h5 className={styles.itemTitle}>Promotion: <span className={styles.brown}>{discountPercentage}%</span> Off</h5>
        </div>
      )}
      {reserve.rate !== 0 && (
        <>
          <div className={styles.summaryItem}>
            <span className={styles.itemTitle}>Regular Rate:</span>
            <span className={`${styles.itemValue} ${styles.originalPrice}`}>${regularRate} USD</span>
          </div>
          <div className={styles.summaryItem}>
            <span className={styles.itemTitle}>Promotion Rate:</span>
            <span className={`${styles.itemValue} ${styles.brown}`}>${promotionRate} USD</span>
          </div>
        </>
      )}
      {isServiceActive && extraService && (
        <>
          <hr />
          <div className={styles.extra_service_active}>
            <div>
              <h5 className={styles.itemTitle}>Extra Service:</h5>
              <div className={`${styles.itemValue} ${styles.name}`}>{extraService.name}</div>
            </div>
            <div className={`${styles.itemTitle} ${styles.price}`}>${extraService.price} USD</div>
          </div>
          <hr />
        </>
      )}
      <div className={styles.summaryItem}>
        <span className={`${styles.itemTitle} ${styles.totalTitle}`}>Total:</span>
        <span className={`${styles.itemValue} ${styles.totalPrice}`}>${isServiceActive && extraService ? promotionRate + extraService.price : promotionRate} USD</span>
      </div>
    </div>
  );
};

export default SummaryPrice;
