import React, { Component, Fragment } from "react";
import { getReservationByIdTR } from "../../api_controller/reservation_controller";
import moment from "moment";
import SummaryPrice from "./summary-price";

export default class index extends Component {
    constructor(props) {
        super();

        this.state = {
            reserve: {},
            empty_data: false,
        };
    }

    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;

        getReservationByIdTR(params.id)
            .then((response) => {
                if (response.data.results.length > 0) {
                    //console.log(response.data.results[0])
                    this.setState({ reserve: response.data.results[0] });
                } else {
                    this.setState({ empty_data: true });
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    render() {
        const {
            ID,
            COSTO,
            NOMBRE,
            TELEFONO,
            CORREO,
            TRANSPORTE,
            NPASAJEROS,
            HOTEL,
            HOTEL_DESTINO,
            FECHA_LLEGADA,
            HORA_IN,
            VUELO_IN,
            AEROLINEA_IN,
            VUELO_OUT,
            HOTEL_EXTRA,
            AEROLINEA_OUT,
            FECHA_SALIDA,
            HORA_OUT,
            DESCUENTO,
            TOTAL_DESCUENTO,
            TOTAL,
        } = this.state.reserve;

        let trip_type = VUELO_OUT === "N/A" ? "One Way" : "Round Trip";

        let label_type = "Arrival Information:";      

        if (trip_type === "One Way" && HOTEL_DESTINO === "AIRPORT SJD") {
            label_type = "Departure Information:";
        }
        console.log(this.state.reserve);
        return (
            <div id="booking-confirm" className="booking-form">
                <div id="top-bar" className="full align-self-bottom">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x grid-padding-y">
                            <div className="small-12 medium-6 large-6 cell small-only-text-center  medium-text-left large-text-left">
                                <a href="/reserve">
                                    <img
                                        height="30"
                                        src={process.env.PUBLIC_URL + "/rci-logo.png"}
                                        alt="RCI"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full" id="breadcrumbs">
                    <div className="grid-container">
                        <div className="contenedor-flex grid-x grid-padding-x"></div>
                    </div>
                </div>

                <div className="grid-container">
                    {this.state.empty_data ? (
                        <div className="modal-header">
                            <div>
                                <h3>Reservation Not Found 404</h3>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            {/*     MODAL HEADER         */}
                            <div className="modal-header">
                                <div>
                                    <h3>Reservation Details ID: {ID}</h3>
                                    {DESCUENTO > 0 ? (
                                        <h4>
                                            Promotion: <span className="promotion_text">{DESCUENTO}%</span>{" "}
                                            off
                                        </h4>
                                    ) : null}
                                </div>                                
                            </div>
                            {/*     MODAL BODY    */}
                            <div className="modal-body">
                                <div className="grid-container">
                                    <div className="grid-x grid-padding-x grid-padding-y">
                                        <SummaryPrice
                                            tripType={trip_type}
                                            isServiceActive={this.state.reserve.SERVICIO_EXTRA}
                                            discountPercentage={10}
                                            reserve={this.state.reserve}
                                        />
                                    </div>
                                    <div className="grid-x grid-padding-x grid-padding-y">
                                        <div className="small-12 medium-6 cell callout">
                                            <h5>Customer information:</h5>
                                            <div id="fullnameTraveler">
                                                <p>
                                                    <b>Full name: </b>{" "}
                                                    <span className="fullname">{NOMBRE}</span>
                                                </p>
                                            </div>
                                            <div id="phoneTraveler">
                                                <p>
                                                    <b>Phone: </b>
                                                    <span className="phone">{TELEFONO}</span>
                                                </p>
                                            </div>
                                            <div id="emailTraveler">
                                                <p>
                                                    <b>E-mail: </b>
                                                    <span className="email">{CORREO}</span>
                                                </p>
                                            </div>
                                            <h5>Transport Selected:</h5>
                                            <p>
                                                <b>Vehicle:</b> <span className="">{TRANSPORTE}</span>
                                            </p>
                                            <p>
                                                <b>Trip Type:</b> <span className="">{trip_type}</span>
                                            </p>
                                            <p>
                                                <b>Passengers Number:</b>{" "}
                                                <span className="pax_num">{NPASAJEROS}</span>
                                            </p>
                                            <p>
                                                <b>Pickup Location:</b>{" "}
                                                <span className="transport">{HOTEL}</span>
                                            </p>
                                            <p>
                                                <b>Destination:</b>{" "}
                                                <span className="transport">{HOTEL_DESTINO}</span>
                                            </p>
                                        </div>
                                        <div className="small-12 medium-6 cell callout">
                                            <h5>{label_type}</h5>
                                            <p>
                                                <b>Date / Time:</b> <br />{" "}
                                                <span className=" arrival_date">{`${FECHA_LLEGADA} ${HORA_IN}:00`}</span>
                                            </p>
                                            <p className="flightNumberArrival">
                                                <b>Flight Number: </b>
                                                <br />
                                                <span className="flight-numberArrival">{VUELO_IN}</span>
                                            </p>
                                            <p className="airlineNameArrival">
                                                <b>Airline Name: </b>
                                                <br />
                                                <span className="airline-nameArrival">{AEROLINEA_IN}</span>
                                            </p>
                                            <hr />
                                            {VUELO_OUT != "N/A" ? (
                                                <Fragment>
                                                    <h5>Departure Information:</h5>
                                                    <p>
                                                        <b>Date / Time:</b>
                                                        <br />
                                                        <span className="departure_date">{`${FECHA_SALIDA} ${HORA_OUT}:00`}</span>
                                                    </p>
                                                    <p className="flightNumberDeparture">
                                                        <b>Departure Pickup Location: </b>
                                                        <br />
                                                        <span className="flight-numberDeparture">
                                                            {HOTEL_EXTRA}
                                                        </span>
                                                    </p>
                                                    <p className="flightNumberDeparture">
                                                        <b>Flight Number: </b>
                                                        <br />
                                                        <span className="flight-numberDeparture">
                                                            {VUELO_OUT}
                                                        </span>
                                                    </p>
                                                    <p className="airlineNameDeparture">
                                                        <b>Airline Name: </b>
                                                        <br />
                                                        <span className="airline-nameDeparture">
                                                            {AEROLINEA_OUT}
                                                        </span>
                                                    </p>
                                                </Fragment>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*     MODAL FOOTER         */}
                            <div className="modal-footer d-flex justify-content-end">
                                <div className="grid-x">
                                    <a
                                        href="javascript:window.print(); void 0;"
                                        className="button btn-secondary">
                                        CLICK FOR PRINT RESERVATION{" "}
                                    </a>
                                </div>
                                <div className="grid-x">
                                    <a href="/reserve" className="button btn-secondary">
                                        RETURN BOOKING
                                    </a>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                <footer className="grid-containerfull ">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-y grid-padding-x align-self-middle">
                            <div className="small-12 medium-8 cell medium-text-left">
                                <p>
                                    Tranroute Cabo Transportation &copy; 2020 <br /> Los Cabos, Baja
                                    California Sur, México
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
