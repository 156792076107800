import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'reactstrap';
import styles from './get-stpp-super-market.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';

// const servicioExtraStatic = {
//   Name:"Stop at the Supermarket or Grocery Store",
//   Price:30
// }
export const GetStopSuperMarket = ({
  reserve,
  isServiceActive,
  setTrueIsServiceActive,
  setFalseIsServiceActive,
  args
}) => {
  // Estado para el Tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // Estado local para el estatus del servicio activo
  const [serviceActiveStatus, setServiceActiveStatus] = useState(isServiceActive);

  // Estado para determinar si el botón debe estar deshabilitado
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    setDisabledButton(!reserve.rate || reserve.destination === 'AIRPORT SJD');
  }, [reserve.rate, reserve.destination]);

  const handleButtonClick = () => {
    if (!serviceActiveStatus) {
      setTrueIsServiceActive(reserve.rate, 30);
      setServiceActiveStatus(true); // Estado local para este componente
    } else {
      setFalseIsServiceActive(reserve.rate, 30);
      setServiceActiveStatus(false); // Estado local para este componente
    }
  };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div className={styles.container}>
        <div className={styles.icon_container}>
          <FontAwesomeIcon icon={faShop} />
        </div>
        <h6>Stop at the Supermarket or Grocery Store</h6>
        <h5 style={{ color: '#000000' }}>${30} USD</h5>
        <Button
          id="disableButton"
          color="secondary"
          onClick={handleButtonClick}
          disabled={disabledButton}
        >
          {serviceActiveStatus ? 'Remove' : 'Add'}
        </Button>
        {disabledButton && (
          <Tooltip
            {...args}
            isOpen={tooltipOpen}
            target="disableButton"
            toggle={toggle}
          >
            Please select Pickup and Destination first. The Destination must be a hotel.
          </Tooltip>
        )}
      </div>
    </div>
  );
};

GetStopSuperMarket.args = {
  autohide: true,
  flip: true,
};

GetStopSuperMarket.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};
