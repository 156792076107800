import React, { Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import Details from "./components/details";
import Confirmation from "./components/confirmation";
import Fees from "./components/fees";
import Reservations from "./components/reservations";
import "foundation-sites/dist/css/foundation.css";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faPlaneArrival,
    faPlaneDeparture,
    faCalendarAlt,
    faUsers,
    faUser,
    faSuitcase,
    faRoute,
    faExclamationTriangle,
    faShuttleVan,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
    faPenAlt,
    faLock,
    faUserFriends,
    faFileInvoiceDollar,
    faSignOutAlt,
    faDownload,
    faClock,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Checkout } from "./components/checkout/checkout";

library.add(
    fab,
    faSignOutAlt,
    faDownload,
    faFileInvoiceDollar,
    faPlaneArrival,
    faPlaneDeparture,
    faCalendarAlt,
    faUsers,
    faSuitcase,
    faRoute,
    faExclamationTriangle,
    faShuttleVan,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faUser,
    faPhone,
    faEnvelope,
    faPenAlt,
    faLock,
    faUserFriends,
    faClock
);

function App() {
    return (
        <Router>
            <Fragment>
                <Route path="/" exact component={Login} />
                <Route path="/reserve" component={Dashboard} />
                <Route path="/reservations-details/:id" component={Details} />
                <Route path="/checkout/:id" component={Checkout} />
                {/*<Route path="/confirmation" component={Confirmation} />*/}
                <Route path="/fees" component={Fees} />
                <Route path="/reservations" component={Reservations} />
            </Fragment>
        </Router>
    );
}

export default App;
