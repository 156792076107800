import React, { useEffect, useState } from 'react';
import { useParams, useHistory} from 'react-router-dom';
import { Container , Row, Col, Button } from 'reactstrap';
import Header from "./../utils/header";
import Footer from "./../utils/footer";
import { getReservationByIdTR } from '../../api_controller/reservation_controller';

export const Checkout = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [paymentID, setPaymentID] = useState('');
    const getReservation = (id) => {
        getReservationByIdTR(id).then((response) => {
            console.log(response.data.results.length)
            if (response.data.results.length > 0) {
                let reserve = response.data
                setData(reserve.results);
                //setPaymentID(reserve[0].payment_id);
                console.log(reserve.results[0])
            }
        });
    };
    useEffect(() => {
        getReservation(id)
    }, [id]);
    return (<>
        <Header/>
            <div>{data[0].ID}</div>
        <Footer/>
    </>
  )
}
