import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './summary-price.module.scss';

const SummaryPrice = ({ reserve, isServiceActive, total, discountPercentage}) => {
    let regularRate = (reserve.rate * 100) / 90;
    regularRate = Number(regularRate.toFixed(1));
    let promotionRate = regularRate - regularRate * 0.1;
    promotionRate = Number(promotionRate.toFixed(1));
  return (
    <div className={styles.reservationSummary}>
      <div className={styles.summaryServiceTitle}>
        <h4 className={styles.itemTitle}>Service: <span>{reserve.trip_type}</span></h4>
      </div>
        {discountPercentage > 0 && (
            <div className={`${styles.summaryItem}`}>
                <h5 className={styles.itemTitle}>Promotion: <span className={styles.brown}>10%</span> Off</h5>
            </div>
        )}
         {reserve.rate !== 0 && (<>
            <div className={styles.summaryItem}>
                <span className={styles.itemTitle}>Regular Rate:</span>
                <span className={`${styles.itemValue} ${styles.originalPrice}`}>${regularRate} USD</span>
            </div>

            <div className={styles.summaryItem}>
                <span className={styles.itemTitle}>Promotion Rate:</span>
                <span className={`${styles.itemValue} ${styles.brown}`}>${promotionRate} USD</span>
            </div>
         </>)}
      {isServiceActive && (
        <>
        <hr />
        <div className={styles.extra_service_active}>
            <div>
                <h5 className={styles.itemTitle}>Extra Service:</h5>
                <div className={`${styles.itemValue} ${styles.name}`}>{reserve.extra_service.name}</div>
            </div>
            <div className={`${styles.itemTitle} ${styles.price}`}>${reserve.extra_service.price} USD</div>
        </div>
        <hr />
        </>
      )}
      <div className={styles.summaryItem}>
        <span className={`${styles.itemTitle} ${styles.totalTitle}`}>Total:</span>
        <span className={`${styles.itemValue} ${styles.totalPrice}`}>${isServiceActive ? promotionRate + reserve.extra_service.price : promotionRate} USD</span>
      </div>
      {reserve.rate === 0 && (
            <div className="small-12 cell">
                <div className="grid-x alert alert-danger text-left">
                    <div className="small-4 cell icon-alert">
                        <FontAwesomeIcon icon="exclamation-triangle" />
                    </div>
                    <div className="small-8">
                        NOT FOUND RATES, <br /> SELECT CORRECT ZONES
                    </div>
                </div>
            </div>
        )} 
    </div>
  );
};

export default SummaryPrice;
