import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import SummaryPrice from "../summary-price/summary-price";

const ReservationSummary = ({ reserve }) => {
    const _transport = reserve.transport === "Sedan" ? "Small SUV" : reserve.transport;
    const { destination, trip_type } = reserve;

    let regularRate = (reserve.rate * 100) / 90;
    regularRate = Number(regularRate.toFixed(1));
    let promotionRate = regularRate - regularRate * 0.1;
    promotionRate = Number(promotionRate.toFixed(1));
    
    return (
        <div className="medium-4 cell">
            <div className="callout">
                <h4>Your Reservation Summary</h4>
                <div className="grid-x">
                    <SummaryPrice
                        isServiceActive={reserve.isServiceActive}
                        discountPercentage={10}
                        reserve={reserve}
                    />
                    {/* <div className="small-12 cell text-right">
                        <h5>
                            Promotion <span className="font-bold promotion_text">10%</span> Off
                        </h5>
                        <h6>
                            Regular Rate: <span className="line-through">$ {regularRate} USD</span>
                        </h6>
                    </div>
                    <div className="small-6 cell">
                        <h4>TOTAL:</h4>
                    </div>
                    <div className="small-6 cell text-right">
                        <h4 className="font-bold"> ${promotionRate} USD</h4>
                    </div>
                    {reserve.rate === 0 && (
                        <div className="small-12 cell">
                            <div className="grid-x alert alert-danger text-left">
                                <div className="small-4 cell icon-alert">
                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                </div>
                                <div className="small-8">
                                    NOT FOUND RATES, <br /> SELECT CORRECT ZONES
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
                <hr />
                <p>
                    <b>Trip Types:</b> <span className="">{reserve.trip_type}</span>
                </p>
                <p>
                    <b>Passengers Number:</b> <span className="pax_num">{reserve.passengers}</span>
                </p>
                <p>
                    <b>Transport Selected:</b> <span className="transport">{_transport}</span>
                </p>
                <p>
                    <b>Pickup Location:</b> <span className="transport">{reserve.pickup}</span>
                </p>
                <p>
                    <b>Destination:</b> <span className="transport">{reserve.destination}</span>
                </p>
                <hr />
                <h5>{destination === "AIRPORT SJD" && trip_type === "One Way" ? "Departure Information:" : "Arrival Information:"}</h5>
                <div className="grid-x">
                    <div className="small-12 cell">
                        <p>
                            <b>Date / Time:</b> <br /> <span className="arrival_date">{moment(reserve.arrival_datetime).format("MM/DD/YYYY")}</span> {" / "}
                            {reserve.arrival_time === "" ? "Time not selected" : <span className="arrival_date">{reserve.arrival_time}</span>}
                        </p>
                    </div>
                    <div className="small-6 cell">
                        <p className="flightNumberArrival">
                            <b>Flight Number: </b>
                            <br />
                            <span className="flight-numberArrival">{reserve.arrival_flight}</span>
                        </p>
                    </div>
                    <div className="small-6 cell">
                        <p className="airlineNameArrival">
                            <b>Airline Name: </b>
                            <br />
                            <span className="airline-nameArrival">{reserve.arrival_airline}</span>
                        </p>
                    </div>
                </div>
    
                {trip_type === "Round Trip" && (
                    <>
                        <hr />
                        <h5>Departure Information:</h5>
                        <div className="grid-x">
                            <div className="small-12 cell">
                                <p>
                                    <b>Date / Time:</b>
                                    <br />
                                    <span className="departure_date">{moment(reserve.departure_datetime).format("DD/MM/YYYY")}</span> {" / "}
                                    {reserve.departure_time === "" ? "Time not selected" : <span className="arrival_date">{reserve.departure_time}</span>}
                                </p>
                            </div>
                            <div className="small-12 cell">
                                <p>
                                    <b>Hotel Departure:</b>
                                    <br />
                                    <span className="departure_date">{reserve.hotel_extra}</span>
                                </p>
                            </div>
                            <div className="small-6 cell">
                                <p className="flightNumberDeparture">
                                    <b>Flight Number: </b>
                                    <br />
                                    <span className="flight-numberDeparture">{reserve.departure_flight}</span>
                                </p>
                            </div>
                            <div className="small-6 cell">
                                <p className="airlineNameDeparture">
                                <b>Airline Name: </b>
                                <br />
                                <span className="airline-nameDeparture">{reserve.departure_airline}</span>
                            </p>
                        </div>
                    </div>
                </>
            )}
            <hr />
            <h5>Contact / Traveler Information:</h5>
            <div id="fullnameTraveler">
                <p>
                    <b>Full name: </b> <span className="fullname">{reserve.contact_name}</span>
                </p>
            </div>
            <div id="phoneTraveler">
                <p>
                    <b>Phone: </b>
                    <span className="phone">{reserve.contact_phone}</span>
                </p>
            </div>
            <div id="emailTraveler">
                <p>
                    <b>E-mail: </b>
                    <span className="email">{reserve.contact_email}</span>
                </p>
            </div>
        </div>
    </div>
    );
}

export default ReservationSummary;
