const configs = {
    // URL_API_BASE: "https://api-rci.transroute.com.mx/api/v1", // STABLE PRODUCCION
    URL_API_BASE: "https://api-testing-rci.transroute.com.mx/api/v1", // STABLE TESTING
    // URL_API_BASE: "http://localhost:4000/api/v1",
    ENVIRONMENT:'DEVELOPMENT'
};

export default configs;






